import React, { useContext, useState, ReactNode, ReactElement } from "react";
import { Redirect } from "react-router-dom";
import "./styles.css";

import LogoTefal from "../../images/Tefal-logo.png";
import LogoKrups from "../../images/Krups-logo.png";
import LogoRowenta from "../../images/Rowenta-logo.png";
import { GlobalStateContext, ActionTypes } from "../../Store";

interface Props {
  children: ReactNode;
}

const BrandChooser = ({ children }: Props) => {
  const { store } = useContext(GlobalStateContext);

  const brands: string[] = ["Tefal", "Krups", "Rowenta"];

  // Check if brand if defined and it's valid from array of brands
  if (store.brand && brands.includes(store.brand)) {
    return children as ReactElement<any>;
  }

  return (
    <div>
      <h1>Choose brand</h1>
      <Brand name="Tefal" img={LogoTefal} />
      <Brand name="Krups" img={LogoKrups} />
      <Brand name="Rowenta" img={LogoRowenta} />
    </div>
  );
};

interface iBrand {
  img: string;
  name: string;
}

const Brand = ({ img, name }: iBrand) => {
  const [brandChosen, setBrandChosen] = useState<boolean>(false);
  const { dispatch } = useContext(GlobalStateContext);

  function selectBrand(name: string) {
    // Set brand in session storage if store is destroyed
    // and the user is not logged in with a token that contains the brand
    sessionStorage.setItem("brand", name);

    dispatch({ type: ActionTypes.SET_BRAND, payload: name });
    setBrandChosen(true);
  }

  if (brandChosen) {
    return <Redirect to="/" />;
  }

  return (
    <button className="brand-button" onClick={() => selectBrand(name)}>
      <img className="brand-logo" width={200} src={img} alt={name} />
    </button>
  );
};

export { BrandChooser };
