import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalStateContext, initialState } from "./Store";
import { BrandChooser } from "./components/BrandChooser";
import ResizeObserver from "resize-observer-polyfill";

import Home from "./pages/home/Home";
import Register from "./pages/register";
import Account from "./pages/account";
import Login from "./pages/login";
import Products from "./pages/products";
import RegisterProduct from "./pages/register-product";
import NotFound from "./pages/404/NotFound";
import RequestPassword from "./pages/request-password-reset";
import ThankYou from "./pages/thank-you";
import PasswordReset from "./pages/password-reset";
import { globalReducer } from "./StoreReducers";
import { ScrollToTop, PrivateRoute } from "./components/common";


const App = () => {
  const [globalStore, dispatchToGlobal] = React.useReducer(globalReducer, initialState);

  useEffect(() => {
    // Listen for height changes and send to parent frame
    const padding = 15;
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const height = entry.contentRect.height;
        window.parent.postMessage({ applicationHeight: height + padding }, "*");
      }
    })
    observer.observe(document.querySelector('body') as HTMLBodyElement)
  }, []);

  return (
    <Router>
      <GlobalStateContext.Provider value={{ store: globalStore, dispatch: dispatchToGlobal }}>
        <ScrollToTop />

        <BrandChooser>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/request-password-reset" component={RequestPassword} />
            <Route exact path="/password-reset" component={PasswordReset} />
            <Route exact path="/register" component={Register} />

            <PrivateRoute exact path="/account" component={Account} />
            <PrivateRoute exact path="/products" component={Products} />
            <PrivateRoute exact path="/register-product" component={RegisterProduct} />
            <PrivateRoute exact path="/thank-you" component={ThankYou} />

            <Route component={NotFound} />
          </Switch>
        </BrandChooser>

      </GlobalStateContext.Provider>
    </Router>
  );
};

export default App;
