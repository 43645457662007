import React, { useState, Fragment } from "react";
import { Row, Col, Form, Input, Button, Alert } from "antd";
import Layout from "../../components/Layout";
import { Link, useLocation } from "react-router-dom";
import qs from "query-string";

const PasswordReset = () => {
  const location = useLocation();

  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  async function onFinish(values: any) {
    try {
      setError(null);
      setLoading(true);
      const res = await fetch(process.env.REACT_APP_API_BASE + "/auth/password-reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${qs.parse(location.search).token}`
        },
        body: JSON.stringify({ password: values.password })
      });

      if (res.ok) {
        setSuccess(true);
      } else {
        throw new Error("Unexpected server error");
      }

    } catch (error) {
      setError(error.name === "Error" ? error.message : "Unexpected server error");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Layout showProfile={false} documentTitle="Reset your password">
      <h1>Reset your password</h1>
      <Row>
        <Col md={12} xs={24}>
          <Form name="login" layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="password"
              label="New password"
              rules={[
                { required: true, message: "Please enter a password" },
                { min: 6, message: "Password must be at least 6 characters" }
              ]}>
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your password"
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Passwords do not match");
                  }
                })
              ]}>
              <Input placeholder="Confirm Password" type="password" />
            </Form.Item>

            {error && <Alert message={error} type="error" showIcon />}
            {success && (
              <Fragment>
                <Alert
                  message={
                    <span>
                      Password successfully reset. You may now <Link to="/login">log in </Link>with
                      your new password.
                    </span>
                  }
                  type="success"
                  showIcon
                />
              </Fragment>
            )}

            <div className="button-bar">
              <Button disabled={loading} loading={loading} type="primary" htmlType="submit">
                Reset Password
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default PasswordReset;
