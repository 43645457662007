import React from "react";
import Cookies from "js-cookie";
import { Route, Redirect, useLocation } from "react-router-dom";
import fetchIntercept from 'fetch-intercept';
import querystring from "query-string";

const PrivateRoute = ({ ...rest }: { [key: string]: any }) => {
  const location = useLocation();
  const queryParams = querystring.parse(location.search);

  // Intercept fetch requests inside private routes
  // if they return a 403 redirect to login
  fetchIntercept.register({
    response: function (response) {
      if (response.status === 403 || response.status === 401) {
        window.location.href = "/login?sessionExpired=1";
      }

      return response;
    }
  });

  // Render the route if we have a token
  if (Cookies.get("token_web") || queryParams.token) {
    return <Route {...rest} />
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          search: "?sessionExpired=1",
          state: { from: location },
        }}
      />
    )
  }
}

export { PrivateRoute };