import React, { useContext, useEffect, useState, Fragment } from "react";
import Layout from "../../components/Layout";
import { Form, Select, Button, Tooltip, Tag, Input, DatePicker, Upload, Alert, message, Row, Col } from "antd";
import { QuestionCircleOutlined, FileAddOutlined } from "@ant-design/icons";
import css from "./RegisterProduct.module.css";
import locale from "antd/es/date-picker/locale/en_GB";
import moment from "moment";
import { Redirect } from "react-router";
import { UploadFile } from "antd/lib/upload/interface";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../../Store";

const RegisterProduct = () => {
  const { store } = useContext(GlobalStateContext);
  const [categories, setCategories] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [modelNumbers, setModelNumbers] = useState<string[]>([]);
  const [purchaseWarning, setPurchaseWarning] = useState<string | null>(null);
  const [proofOfPurchase, setProofOfPurchase] = useState<UploadFile | undefined>(undefined);

  const { Option } = Select;
  const { Dragger } = Upload;
  const [form] = Form.useForm();

  async function getModelNumbers(category: string) {
    try {
      setLoading(true);
      setError(null);
      const res = await fetch(`https://productrepairapi.azurewebsites.net/api/Products/${encodeURI(category)}/ModelNumbers/${store.brand}`, {
        headers: {
          Authorization: "Basic dXNlcm5hbWU6cGFzc3dvcmQ=",
          API_KEY: "X-DODEC-DKK"
        }
      });

      if (res.ok) {
        const resData = await res.json();        
        setModelNumbers(resData.filter((modelNumber: string) => modelNumber !== 'UNUSED'));
      } else {
        throw new Error("Unexpected server error");
      }
    } catch (error) {
      setError(error.name === "Error" ? error.message : "Unexpected server error");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getCategories() {
      try {
        setLoading(true);
        setError(null);
        const res = await fetch(`https://productrepairapi.azurewebsites.net/api/Products/Categories/${store.brand}`, {
          headers: {
            Authorization: "Basic dXNlcm5hbWU6cGFzc3dvcmQ=",
            API_KEY: "X-DODEC-DKK"
          }
        });

        if (res.ok) {
          const resData = await res.json();
          setCategories(resData);
        } else {
          throw new Error("Unexpected server error");
        }
      } catch (error) {
        setError(error.name === "Error" ? error.message : "Unexpected server error");
        if (error.name !== "Error") {
          throw error;
        }
      } finally {
        setLoading(false);
      }
    }

    getCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmit(values: { [key: string]: any }) {
    try {
      setLoading(true);

      const formData: FormData = new FormData();
      formData.append("brand", store.brand);
      formData.append("category", values.category);
      formData.append("modelNumber", values.customModelNumber || values.modelNumber);
      formData.append("retailer", values.retailer);
      formData.append("manufactureCode", values.manufactureCode);
      formData.append("dateOfPurchase", values.dateOfPurchase);

      if (values.customModelNumber) {
        formData.append("customModelNumber", JSON.stringify(true));
      }

      if (proofOfPurchase) {
        formData.append("proofOfPurchase", proofOfPurchase as any, proofOfPurchase.name);
      }

      const res = await fetch(`${process.env.REACT_APP_API_BASE}/customers/${store.customer.id}/products`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${Cookies.get("token_web")}`
        }
      });

      if (res.ok) {
        setSuccess(true);
      }
    } catch (error) {
      message.error(error.name === "Error" ? error.message : "Unexpected server error when registering product");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  interface iHelpTooltip {
    title: string;
  }

  const HelpTooltip = ({ title }: iHelpTooltip) => {
    return (
      <span>
        {title}{" "}
        <Tooltip
          arrowPointAtCenter
          overlayClassName={css.tooltip}
          placement="rightTop"
          trigger="click"
          title={
            <Fragment>
              <p>
                All {store.brand} products have a rating label, similar to the one below detailing the
                model number and the four digit date of manufacture code, which will show the
                information for your product.
              </p>
              <img
                src={`/images/${store.brand}-code-sample.png`}
                alt={`${store.brand} product model number example`}
              />
            </Fragment>
          }>
          <Tag color="purple">
            <QuestionCircleOutlined style={{ marginRight: 5 }} />
            What's this?
          </Tag>
        </Tooltip>
      </span>
    );
  };

  interface iRetailer {
    name: string;
    img: string;
  }

  const retailers: iRetailer[] = [
    {
      name: "Home and Cook",
      img: "https://www.tefal.co.uk/medias/HOME-COOK.jpg?context=bWFzdGVyfGltYWdlc3wyOTg5MnxpbWFnZS9qcGVnfGltYWdlcy9oMjQvaDlmLzEyMzg0MjA3MTc1NzEwLmpwZ3xiNTQ1NjI3ZGQ0MmU0MjkxYWRlMzMxNmRmODU3MDI1NWFhNmRlNDQzYjgzZDM5OGIzYTVhZGYwY2ExOTBkMGQ5"
    },
    {
      name: "Amazon",
      img: "https://www.tefal.co.uk/medias/amazon.jpg?context=bWFzdGVyfHJvb3R8NDM5NHxpbWFnZS9qcGVnfGgwMC9oYmIvODgxMzA5NzU0OTg1NC5qcGd8N2Q4OTI5OWI1M2U1MzY4NjFiODMzZDk5MGFjMzAwY2Y2MDQ3MTQ3ODk0OTA1ZjQ4NmQzMmI5NWQ2MDNiMzE4Zg"
    },
    {
      name: "Very",
      img: "https://www.tefal.co.uk/medias/very.jpg?context=bWFzdGVyfHJvb3R8NDA5NXxpbWFnZS9qcGVnfGg5MS9oNjQvODg5MTQ0MDIzNDUyNi5qcGd8ZTc5MzJlZmJhYWQyNGZjNWMyZDQyNDJhNzE5MDA3YzVjNGZiNGFiYTg0YmM5ZWI3M2ViYWQyNDVhNjAyZmM0OA"
    },
    {
      name: "Argos",
      img: "https://www.tefal.co.uk/medias/argos.jpg?context=bWFzdGVyfHJvb3R8NjMxNXxpbWFnZS9qcGVnfGgyMS9oMjQvODgxMzA5NzIyMjE3NC5qcGd8ZjYzZmNhMDM1NTMyYWU0NTQyZGI2MmYzNmJiMWQ4MzEwYWVlYWE4NGQxNmI3ZmUzNjFjYmVhNzk0MTZhN2MzOA"
    },
    {
      name: "Currys",
      img: "https://www.tefal.co.uk/medias/currys.jpg?context=bWFzdGVyfHJvb3R8NTYyM3xpbWFnZS9qcGVnfGhhYS9oZWYvODgxMzA5NzY0ODE1OC5qcGd8MThmMzZlMDJjMTMyZTJiZDE2MDcxYzQ0ZDQ3ZmJlNjBjMGJlMzAwZjdhYjY5Yjk2MGFlNmNlZTZlMjdhZGQ4Yg"
    },
    {
      name: "John Lewis",
      img: "https://www.tefal.co.uk/medias/john-lewis.jpg?context=bWFzdGVyfHJvb3R8MzY5MnxpbWFnZS9qcGVnfGhiYy9oMzYvODgxMzA5Nzk0MzA3MC5qcGd8MWFjYzNlNmY3YTM5Y2MyOTYwYTNkNmVlZTI3OGRkNWJkZGFiZTU3ZjA3YTZhNDYwNjE1N2YyODdkMmQ5ZjYyNw"
    },
    {
      name: "AO",
      img: "https://www.tefal.co.uk/medias/AO.jpg?context=bWFzdGVyfGltYWdlc3wyNjIwNXxpbWFnZS9qcGVnfGltYWdlcy9oZWUvaGJjLzEyMzg0MjA4MDkzMjE0LmpwZ3wxZDczMWI5ZDA4MmJiZTgzZWFmZTU3MTk4MjcyYjU3YzE2MDViNmU5ZGNiMzA5ZTBiY2NhYmNlOGFkYTcwZDJk"
    },
    {
      name: "Littlewoods",
      img: "https://www.tefal.co.uk/medias/littlewoods.jpg?context=bWFzdGVyfGltYWdlc3wxNjE5MHxpbWFnZS9qcGVnfGltYWdlcy9oOTUvaDBiLzEyMzg0MjA3ODMxMDcwLmpwZ3w2NGEzZTUwOWM5MTUzMDA2NjVmODgzOWQ2NDllNzAxNWU1MmU2MDczNDEyOTRjNDA4Y2Y0N2U1MDA2MDJmZDhh"
    },
    {
      name: "Debenhams",
      img: "https://www.tefal.co.uk/medias/DEBENHAMS.jpg?context=bWFzdGVyfGltYWdlc3wxODAwM3xpbWFnZS9qcGVnfGltYWdlcy9oNmUvaGRmLzEyMzg0MjA3NzY1NTM0LmpwZ3w5M2JiMjJlMTA0NmE5YjJhODYzOWFjZGQ3MGNhY2RlNmQxNjUwYzYyZDY3MDQ5YjFjZTI1ZjY2YzQyYzI4ODMx"
    }
  ];

  if (success) {
    return <Redirect to="/thank-you" />;
  }

  return (
    <Layout documentTitle="Register your product">
      <h1>Register your {store.brand} product</h1>

      {error ? <Alert showIcon message={error} type="error" /> : (
        <Fragment>
          <h2>Model number and purchase date</h2>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Row>
              <Col md={18} xs={24}>
                <p>
                  Please select the product you wish to register. And when it was purchased. Simply
                  select the model type and we will help you find the Model Number.
                </p>

                <Form.Item
                  name="category"
                  label="Product Category"
                  rules={[{ required: true, message: "Please choose a category" }]}>
                  <Select
                    size="large"
                    placeholder="Choose category"
                    allowClear
                    loading={loading}
                    disabled={loading}
                    onChange={(value) => {
                      getModelNumbers(value as string);
                      form.setFieldsValue({
                        modelNumber: '',
                      });
                    }}>
                    {categories
                      .sort((a, b) => a.localeCompare(b))
                      .map((category) => (
                        <Option key={category} value={category}>
                          {category}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      getFieldValue("category") && (
                        <Form.Item
                          name="modelNumber"
                          label={<HelpTooltip title="Model Number" />}
                          rules={[{ required: true, message: "Please select a model number" }]}>
                          <Select
                            size="large"
                            placeholder="Choose model number"
                            allowClear
                            loading={loading}
                            disabled={loading}>
                            {modelNumbers
                              .sort((a, b) => a.localeCompare(b))
                              .map((model) => (
                                <Option key={model} value={model}>
                                  {model}
                                </Option>
                              ))}
                            <Option value="Not listed">Not listed</Option>
                          </Select>
                        </Form.Item>
                      )
                    );
                  }}
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.modelNumber !== currentValues.modelNumber
                  }>
                  {({ getFieldValue }) => {
                    return getFieldValue("modelNumber") === "Not listed" ? (
                      <Form.Item
                        rules={[{ required: true, message: "Please enter a model number" }]}
                        extra="Note: The model number you enter will be validated once your product is registered."
                        name="customModelNumber">
                        <Input size="large" placeholder="Enter model number" />
                      </Form.Item>
                    ) : (
                        <Fragment></Fragment>
                      );
                  }}
                </Form.Item>

                <Form.Item
                  name="manufactureCode"
                  label={<HelpTooltip title="Date of Manufacture Code (4 digits)" />}
                  rules={[
                    {
                      pattern: new RegExp("^[0-9]*$"),
                      message: (
                        <span>
                          This code should only contain numbers
                          <br />
                        </span>
                      )
                    },
                    { max: 4, message: "This code should be four digits" },
                    { min: 4, message: "This code should be four digits" },
                    { required: true, message: "Please enter date of manufacture code" }
                  ]}>
                  <Input
                    size="large"
                    placeholder="Enter date of manufacture code"
                    maxLength={4}
                    minLength={4}
                    onBlur={() => form.setFieldsValue({ dateOfPurchase: null })}
                  />
                </Form.Item>

                <Form.Item
                  name="dateOfPurchase"
                  label="Date of Purchase"
                  rules={[{ required: true, message: "Please select your date of purchase" }]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    size="large"
                    locale={locale}
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Select date"
                    disabledDate={(current) => current && current > moment().endOf("day")}
                    onChange={(date: any, dateString: string) => {
                      if (date && dateString) {
                        setPurchaseWarning(null);
                        const dateOfManufacture = form.getFieldValue("manufactureCode");

                        const sixMonthsAgo = moment()
                          .clone()
                          .subtract(6, "months")
                          .subtract(1, "days");
                        const withinSixMonths = date.isBetween(sixMonthsAgo, moment());

                        if (dateOfManufacture) {
                          const dopMonth = Number(dateString.substr(3, 2));
                          const dopWeeks = Number(dopMonth * 4);
                          const domWeeks = Number(dateOfManufacture.substr(0, 2));
                          const weekDifference = Math.abs(dopWeeks - domWeeks);

                          const currentYear = new Date()
                            .getFullYear()
                            .toString()
                            .substr(-2);
                          const year = dateOfManufacture.substr(-2);

                          if ((withinSixMonths && weekDifference > 26) || currentYear !== year) {
                            setPurchaseWarning(
                              "You may be required for proof of purchase, should a guarantee repair be required."
                            );
                          }
                        }

                        if (!withinSixMonths) {
                          setPurchaseWarning(
                            "We note you purchased this product over 6 months ago, you may be required to show proof of purchase should a guarantee repair be required."
                          );
                        }
                      }
                    }}
                  />
                </Form.Item>

                {purchaseWarning && (
                  <Alert style={{ marginTop: 10 }} message={purchaseWarning} type="warning" showIcon />
                )}

                <Form.Item
                  name="retailer"
                  label="Retailer"
                  rules={[{ required: true, message: "Please select a retailer" }]}>
                  <Select size="large" placeholder="Select retailer">
                    {retailers
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((retailer) => (
                        <Option value={retailer.name} key={retailer.name}>
                          <Tooltip
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0}
                            placement="left"
                            title={
                              <img
                                className={css.retailerLogo}
                                src={retailer.img}
                                alt={retailer.name}
                              />
                            }>
                            <span className={css.retailerTitle}>{retailer.name}</span>
                          </Tooltip>
                        </Option>
                      ))}

                    <Option value="Not listed">Not listed</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="proof"
                  label="Upload proof of purchase (optional)"
                  extra="Uploading proof of purchase will help us validate your purchase should a guarantee repair be required.">
                  <Dragger
                    fileList={proofOfPurchase ? [proofOfPurchase] : []}
                    accept=".jpg,.jpeg,.png,.pdf"
                    name="proof-of-purchase"
                    beforeUpload={(file: UploadFile) => {
                      const isCorrectFormat =
                        file.type === "image/jpeg" ||
                        file.type === "image/png" ||
                        file.type === "image/jpg" ||
                        file.type === "image/pdf" ||
                        file.type === "application/pdf";
                      if (!isCorrectFormat) {
                        message.error("File must be one of these formats: .jpg, .jpeg, .png, .pdf");
                      }

                      const isCorrectSize = file.size / 1024 / 1024 < 8;
                      if (!isCorrectSize) {
                        message.error("File size limit: 8MB");
                      }

                      const good = isCorrectFormat && isCorrectSize;
                      if (good) {
                        setProofOfPurchase(file);
                        return false;
                      }

                      return isCorrectFormat && isCorrectSize;
                    }}>
                    <p className="ant-upload-drag-icon">
                      <FileAddOutlined />
                    </p>

                    <p className="ant-upload-text">
                      Click or drag a file to this area to upload your receipt
                    </p>
                    <p className="ant-upload-hint">File formats accepted: .jpg, .jpeg, .png, .pdf</p>
                  </Dragger>
                </Form.Item>

                <p>
                  Note: If you can register more than one product in your user account, you will have
                  the option after you have completed the registration of this product.
                </p>

                <div className="button-bar">
                  <Link to="/products">
                    <Button disabled={loading} htmlType="button" size="large">
                      Back
                    </Button>
                  </Link>

                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={loading}
                    loading={loading}>
                    Register Product
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Fragment>
      )}
    </Layout>
  );
};

export default RegisterProduct;
