import React from "react";
import Layout from "../../components/Layout";
import { CustomerForm } from "../../components/CustomerForm";



const Account = () => {

  return (
    <Layout showProfile={false}>
      <h1>Update your account</h1>
      <CustomerForm type="update-customer" />
    </Layout>
  );
};

export default Account;
