import React, { useContext, ReactNode, useEffect } from "react";
import { Layout } from "antd";
import css from "./Layout.module.css";
import { Profile } from "../Profile";
import { withRouter, RouteComponentProps } from "react-router";
import { GlobalStateContext } from "../../Store";
import { Link } from "react-router-dom";

interface iLayout extends RouteComponentProps {
  children: ReactNode;
  showProfile?: boolean;
  documentTitle?: string;
}

const Main = ({ children, location, showProfile = true, documentTitle }: iLayout) => {
  const { store } = useContext(GlobalStateContext);

  useEffect(() => {
    document.title = documentTitle
      ? `${documentTitle} | ${store.brand} Product Registration`
      : `${store.brand} Product Registration`;
  }, [documentTitle, store.brand]);

  const { Content } = Layout;

  const pageName = location.pathname === "/" ? "home" : location.pathname.substr(1);

  function linkBack(): string {
    return location.pathname === "products" ? "/" : "/products";
  }

  return (
    <Layout className={[store.brand, css.container, pageName].join(" ")}>
      {showProfile && <Profile />}
      <Content>
        {
          (!["/", "/login", "/products"].includes(location.pathname)) ?
            (
              <Link to={linkBack()}>
                <img alt="" className={css.backArrow} src="/images/go-back-arrow.svg" />
              </Link>
            )
            :
            null
        }
        <p className={[css.title, "title"].join(" ")}>{store.brand} Secure Product Registration Site</p>
        {children}
      </Content>
      <div data-iframe-height />
    </Layout>
  );
};

export default withRouter(Main);
