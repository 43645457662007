import React, { useContext, Fragment } from "react";
import { GlobalStateContext } from "../../Store";

import BannerImg from "../../images/banner-registration.png";
import Layout from "../../components/Layout";
import { Button } from "antd";
import { Link } from "react-router-dom";
import css from "./Home.module.css";

const Home = () => {
  const { store } = useContext(GlobalStateContext);

  return (
    <Layout showProfile={false}>
      <img src={BannerImg} alt={`${store.brand} Product Registration`} className={css.banner} />

      <p>
        You can register all your {store.brand} products, and take advantage of a number of benefits such
        as:
      </p>
      <ul>
        <li>Special offers/promotions</li>
        <li>Product tips</li>
        <li>Direct access to service and support</li>
        <li>Product registration history and much more...</li>
      </ul>
      <p>
        Please complete the simple two stage process about you and all your {store.brand} products, it
        shouldn't take more than a few minutes.
      </p>

      <img
        src={`/images/products-${store.brand}.png`}
        className={css.productBanner}
        alt={store.brand + " products"}
      />

      <div className={css.bottom}>
        {store.customer && store.customer.brand === store.brand ? (
          // Already logged in
          <Fragment>
            <Link to="/products">
              <Button size="large" className={css.getStarted} type="primary">
                Get Started
              </Button>
            </Link>
          </Fragment>
        ) : (
            <Fragment>
              <Link to="/register">
                <Button size="large" className={css.getStarted} type="primary">
                  Get Started
              </Button>
              </Link>

              {/* Login Area */}
              <p>Already have an account?</p>
              <Link to="/login">Log in</Link>
            </Fragment>
          )}
      </div>
    </Layout>
  );
};

export default Home;
