import React, { useContext } from "react";
import Cookies from "js-cookie";
import { ActionTypes, GlobalStateContext } from "../../Store";
import { Button, Avatar, message } from "antd";
import { useHistory } from "react-router";
import css from "./Profile.module.css";
import { UserAddOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";

const Profile = () => {
  const { store, dispatch } = useContext(GlobalStateContext);
  const history = useHistory();

  function handleLogout() {
    dispatch({ type: ActionTypes.SET_CUSTOMER, payload: null });
    Cookies.remove("token_web");
    Sentry.configureScope((scope) => scope.clear())
    message.info("Successfully logged out");
    history.push("/login");
  }


  if (!store.customer.id) {
    return null;
  }

  return (
    <div className={css.profile}>
      <Link to="/account" className={css.profileLink}>
        <Avatar size="small" icon={<UserAddOutlined />} /> &nbsp;My Account
      </Link>

      <Button size="small" onClick={handleLogout}>
        Log out
      </Button>
    </div>
  );
};

export { Profile };

