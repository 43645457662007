import React, { useContext, useState, Fragment } from "react";
import { Col, Form, Input, Button, Alert, Row } from "antd";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { GlobalStateContext } from "../../Store";

const RequestPassword = () => {
  const { store } = useContext(GlobalStateContext);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  async function onFinish(values: any) {
    try {
      setError(null);
      setLoading(true);
      const res = await fetch(process.env.REACT_APP_API_BASE + "/auth/request-password-reset", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          brand: store.brand,
          email: values.email,
          type: "customer"
        })
      });

      if (res.status === 404) {
        throw new Error("There are no users associated with this email address");
      }

      if (res.ok) {
        setSuccess(true);
      } else {
        throw new Error("Unexpected server error");
      }

    } catch (error) {
      setError(error.name === "Error" ? error.message : "Unexpected server error");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Layout showProfile={false} documentTitle="Reset Password">
      <h1>Request a new password</h1>
      <p>Tell us your registered email and we'll send you a link to reset it.</p>

      <Row>
        <Col md={12} xs={24}>
          <Form name="login" layout="vertical" onFinish={onFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter your email address" }]}>
              <Input type="email" />
            </Form.Item>

            {error && <Alert message={error} type="error" showIcon />}
            {success && (
              <Fragment>
                <Alert
                  message="You will receive an email shortly with instructions to reset your password."
                  type="success"
                  showIcon
                />

                <p>
                  <small>
                    Please check your spam/junk/clutter if you do not see this email in your inbox.
                  </small>
                </p>
              </Fragment>
            )}

            <div className="button-bar">
              <Link to="/login">
                <Button disabled={loading} htmlType="button">
                  Back
                </Button>
              </Link>

              <Button disabled={loading} loading={loading} type="primary" htmlType="submit">
                Reset
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default RequestPassword;
