import { createContext } from "react";
import { Customer, Brands, Token } from "./models";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import qs from "query-string";

// Decode token if it exists
const webToken = Cookies.get("token_web") || null;
const decodedToken = webToken && webToken !== "undefined" ? jwt_decode(webToken) as Token : null;
const brandParam = qs.parse(window.location.search).brand || null;

// Determine active brand
function setBrand() {
  if (brandParam) {
    return brandParam;
  }

  if (decodedToken && decodedToken.brand) {
    return decodedToken.brand;
  }

  return sessionStorage.getItem("brand");
}

export enum ActionTypes {
  "SET_CUSTOMER",
  "SET_BRAND"
}

export interface Action {
  type: ActionTypes;
  payload?: any;
}

export interface Store {
  customer: Customer;
  brand: Brands;
}

export const initialState: Store = {
  customer: decodedToken ? decodedToken : null as any,
  brand: setBrand() as any
};

export const GlobalStateContext = createContext<{ store: Store; dispatch: (action: Action) => void; }>({ store: initialState, dispatch: () => { } });