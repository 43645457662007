import React, { useEffect, useContext, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import { Button, Alert, Card, Spin, Result } from "antd";
import { Product as ProductModel } from "../../models";
import css from "./Products.module.css";
import { CalendarFilled, PaperClipOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";
import moment from "moment";
import Cookies from "js-cookie";
import { GlobalStateContext } from "../../Store";

const Products = () => {
  const { store } = useContext(GlobalStateContext);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [deletedProducts, setDeletedProducts] = useState<string[]>([]);

  async function getProducts() {
    try {
      setError(null);
      setLoading(true);
      const res = await fetch(process.env.REACT_APP_API_BASE + `/customers/${store.customer.id}/products`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${Cookies.get("token_web")}`
        }
      });

      if (res.ok) {
        const resData = await res.json();
        setProducts(resData);
      } else {
        throw new Error("Unexpected error fetching product data");
      }
    } catch (error) {
      setError(error.name === "Error" ? error.message : "Unexpected error fetching product data");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getProducts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  interface iProduct {
    isDeleted: boolean;
    data: ProductModel;
  }

  const Product = ({ data, isDeleted }: iProduct) => {
    const [loading, setLoading] = useState<boolean>(false);

    async function confirm(e: any, id: string) {
      try {
        setLoading(true);

        const deleteProductRes = await fetch(`${process.env.REACT_APP_API_BASE}/customers/${store.customer.id}/products/${id}`, {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            "Authorization": `Bearer ${Cookies.get("token_web")}`
          },
        });

        if (deleteProductRes.ok) {
          setDeletedProducts([...deletedProducts, id]);
          message.success("This product has been removed from your account.");
        } else {
          throw new Error("Unexpected server error when deleting product");
        }
      } catch (error) {
        message.error(error.name === "Error" ? error.name : "Unexpected server error when deleting product");
        if (error.name !== "Error") {
          throw error;
        }
      } finally {
        setLoading(false);
      }
    }

    const { id, category, modelNumber, dateOfPurchase, createdAt, proofOfPurchase } = data;

    return (
      <Card
        className={[css.product, isDeleted ? css.deleted : ""].join(" ")}
        style={{ padding: 8 }}
        bodyStyle={{ padding: 8 }}>
        <h2
          className={[css.modelNumber, "modelNumber"].join(
            " "
          )}>{`${store.brand} ${category} (${modelNumber})`}</h2>
        <p className={css.dates}>
          <CalendarFilled />
          Date purchased: {moment(dateOfPurchase).format("DD/MM/YYYY")}
        </p>
        <p className={css.dates}>
          <CalendarFilled />
          Date registered: {moment(createdAt).format("DD/MM/YYYY")}
        </p>

        {proofOfPurchase && (
          <Fragment>
            <a href={proofOfPurchase}>
              <Button style={{ display: "block" }} icon={<PaperClipOutlined />} type="default">
                Download proof of purchase
              </Button>
            </a>
          </Fragment>
        )}

        {isDeleted && (
          <Alert
            style={{ position: "relative", zIndex: 2 }}
            message="This product has been removed from your account."
            type="success"
            showIcon
          />
        )}

        {!isDeleted && (
          <Popconfirm
            placement="rightBottom"
            title="This product will be removed from your account, are you sure?"
            onConfirm={(e) => confirm(e, id)}
            okText="Yes, remove product"
            cancelText="Cancel"
            disabled={loading || isDeleted}>
            <Button
              style={{ paddingLeft: 0, color: "gray", display: "block", fontSize: 12 }}
              disabled={loading || isDeleted}
              type="link">
              I no longer own this product
            </Button>
          </Popconfirm>
        )}

        {loading && <Spin />}
      </Card>
    );
  };

  return (
    <Layout documentTitle="Your Products">
      {loading ? <Spin /> : error ? <Alert message={error} type="error" showIcon /> : products.length > 0 ? (
        <Fragment>
          <h1>Your products</h1>
          <div className={css.cardsWrapper}>
            {products.map((product) => (
              <Product
                key={product.id}
                data={product}
                isDeleted={deletedProducts.includes(product.id)}
              />
            ))}
          </div>

          <Link to="/register-product">
            <Button block size="large" type="primary">
              Register another product
              </Button>
          </Link>
        </Fragment>
      ) : (
          <Result
            style={{ background: "#f7f7f7", margin: "25px 0" }}
            title={
              <span>
                <h1>Your account registration is complete.</h1>
              </span>
            }
            subTitle={`You now can register your ${store.brand} products to this account.`}
            extra={[
              <Link key="register-link" to="/register-product">
                <Button size="large" type="primary">
                  Register a product
              </Button>
              </Link>
            ]}
          />
        )}
    </Layout>
  );
};

export default Products;
