import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Alert, Row, Col } from "antd";
import Layout from "../../components/Layout";
import { Link, RouteComponentProps, Redirect, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { GlobalStateContext, ActionTypes } from "../../Store";
import * as Sentry from "@sentry/browser";

const Login = ({ history }: RouteComponentProps) => {
  const { store, dispatch } = useContext(GlobalStateContext);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  // Prefer this over Redirect as async functions are cleaned up avoiding memory leaks
  // useEffect(() => {
    
  //   if (success) {      
  //     history.push("/products");
  //   }
  // }, [success]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onFinish(values: any) {
    try {
      setError(null);
      setLoading(true);
      const loginRes = await fetch(process.env.REACT_APP_API_BASE + "/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          brand: store.brand,
          email: values.email,
          password: values.password,
          type: "customer"
        })
      });

      if (loginRes.status === 401) {
        throw new Error("Incorrect email or password");
      }

      if (loginRes.status === 404) {
        throw new Error("There are no users associated with this email address");
      }

      if (loginRes.ok) {
        const loginResData = await loginRes.json();
        Cookies.set("token_web", loginResData.access_token, { expires: 90, sameSite: "none", secure: true });
        dispatch({ type: ActionTypes.SET_CUSTOMER, payload: { id: loginResData.id, email: loginResData.email } });

        // Decode the token and set sentry session
        const tokenData = jwtDecode(loginResData.access_token) as any;
        Sentry.setUser({
          id: tokenData.sub,
          email: tokenData.email,
          brand: tokenData.brand
        });

        setSuccess(true);
      } else {
        throw new Error("Unexpected server error");
      }

    } catch (error) {
      setError(error.name === "Error" ? error.message : "Unexpected server error");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  if(success){
    return (
      <Redirect to="/products" />
    )
  }

  return (
    <Layout showProfile={false} documentTitle="Log in">
      <h1>Log in to your account to register your products</h1>

      <p>
        Don’t have an account? <Link to="/register">Create your account here</Link>
      </p>

      <p>
        <Link to="/request-password-reset">I've forgotten my password</Link>
      </p>

      <Form name="login" layout="vertical" onFinish={onFinish}>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter your email address" }]}>
              <Input type="email" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please enter your password" }]}>
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        {error && <Alert message={error} type="error" showIcon />}
        <div className="button-bar">
          <Button disabled={loading} loading={loading} type="primary" htmlType="submit">
            Log in
          </Button>
        </div>
      </Form>
    </Layout>
  );
};

export default withRouter(Login);
