import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';
import App from "./App";
import "./index.css";
import ErrorBoundary from "./components/common/ErrorBoundry";

Sentry.init({
  dsn: "https://e5857fa9f7494ada9a07329de4c22dc1@o371638.ingest.sentry.io/5193579",
  release: 'product-registration-web@' + process.env.npm_package_version,
  environment: process.env.REACT_APP_STAGE
});

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);