import { Reducer } from "react";
import { Store, Action, ActionTypes } from "./Store";

export const globalReducer: Reducer<Store, Action> = (store, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_CUSTOMER:
      return { ...store, customer: payload };

    case ActionTypes.SET_BRAND:
      return { ...store, brand: payload };

    default:
      return store;
  }
};