import React, { useContext, useState, useEffect } from "react";
import { Input, Form, Checkbox, Button, Row, Col, Select, Radio, Divider, Alert, Spin, message } from "antd";
import { GlobalStateContext, ActionTypes } from "../../Store";
import { Link, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { Customer } from "../../models";
import * as Sentry from "@sentry/browser";

interface Props {
  type: "create-customer" | "update-customer";
}

const CustomerForm = ({ type }: Props) => {
  const { store, dispatch } = useContext(GlobalStateContext);
  const { Option } = Select;

  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);
  const [customer, setCustomer] = useState<null | Customer>(null);

  const accessToken = Cookies.get("token_web");

  async function fetchCustomer() {
    try {
      setLoading(true);
      const res = await fetch(process.env.REACT_APP_API_BASE + "/me", {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Authorization": `Bearer ${accessToken}`
        }
      });

      if (res.ok) {
        const resData = await res.json();
        setCustomer(resData);
      } else {
        message.error("Failed to load user data. Please try again");
      }
    } catch (error) {
      message.error(error.name === "Error" ? error.message : "Failed to load user data. Please try again");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (accessToken && type === "update-customer") {
      fetchCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, type]);

  async function onFinish(values: any) {
    try {
      setLoading(true);
      setError(null);
      const res = await fetch(type === "create-customer" ? process.env.REACT_APP_API_BASE + "/customers" : process.env.REACT_APP_API_BASE + `/customers/${customer?.id}`, {
        method: type === "create-customer" ? "POST" : "PATCH",
        headers: {
          "Content-Type": "application/json",
          ...(type === "update-customer" && { "Authorization": `Bearer ${Cookies.get("token_web")}` })
        },
        body: JSON.stringify({
          ...values,
          brand: store.brand,
          agreeNewsletter: values.agreements ? values.agreements.includes("agreeNewsletter") : false,
          agreePackages: values.agreements ? values.agreements.includes("agreePackages") : false
        })
      });

      if (res.status === 409) {
        throw new Error("There is already an account associated to this email address");
      }

      if (res.ok === false) {
        throw new Error("Unexpected error updating account");
      }

      const resData = await res.json();

      if (resData.access_token && type === "create-customer") {
        Cookies.set("token_web", resData.access_token, { sameSite: "none", secure: true });

        // Set new customer in store
        dispatch({ type: ActionTypes.SET_CUSTOMER, payload: { id: resData.id, email: resData.email } });

        // Decode the token and set sentry session
        const tokenData = jwt_decode(resData.access_token) as any;
        Sentry.setUser({
          id: tokenData.sub,
          email: tokenData.email,
          brand: tokenData.brand
        });
      }

      setSuccess(true);
    } catch (error) {
      setError(error.name === "Error" ? error.message : "Unexpected server error");
      if (error.name !== "Error") {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  function onFinishFailed() {
    setError("There are errors on the form, please check and try again");
  }

  if (success) {
    type === "update-customer" ? message.success("Account details updated") : message.success("Your account has been created");
    return <Redirect to="/products" />;
  }

  if (type === "update-customer" && loading) {
    return <Spin />;
  }

  return (
    <Form
      layout="vertical"
      name="customer-form"
      initialValues={customer ? {
        ...customer,
        confirmEmail: customer.email,
        agreements: [customer.agreePackages && "agreePackages", customer.agreeNewsletter && "agreeNewsletter"]
      } : {}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Row gutter={50}>
        <Col md={12} xs={24}>
          <h2>Your name</h2>
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter your title" }]}>
            <Select placeholder="Choose title" allowClear>
              <Option value="Mr">Mr</Option>
              <Option value="Mrs">Mrs</Option>
              <Option value="Miss">Miss</Option>
              <Option value="Ms">Ms</Option>
              <Option value="Dr">Dr</Option>
              <Option value="Professor">Professor</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="firstName"
            label="First name"
            rules={[{ required: true, message: "Please enter your first name" }]}>
            <Input placeholder="First name" />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Last name"
            rules={[{ required: true, message: "Please enter your last name" }]}>
            <Input placeholder="Last name" />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <h2>Your Contact Details</h2>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please enter your email"
              }
            ]}>
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item
            name="confirmEmail"
            label="Confirm email"
            rules={[
              {
                required: true,
                message: "Please confirm your email"
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("email") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Emails do not match");
                }
              })
            ]}>
            <Input placeholder="Email" type="email" />
          </Form.Item>
          <Form.Item name="telephone" label="Telephone">
            <Input placeholder="Telephone" />
          </Form.Item>
          <Form.Item name="mobile" label="Mobile">
            <Input placeholder="Mobile" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={50}>
        <Col md={12} xs={24}>
          <h2>Use</h2>
          <Form.Item
            name="type"
            label="Type of registration"
            rules={[{ required: true, message: "Please select your type of use" }]}>
            <Radio.Group size="large">
              <Radio value="Domestic">Domestic use</Radio>
              <Radio value="Commercial">Commercial use</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={50}>
        <Col md={12} xs={24}>
          <h2>Your Address</h2>
          <Form.Item
            name="country"
            label="Country"
            rules={[{ required: true, message: "Please select your country" }]}>
            <Radio.Group size="large">
              <Radio value="UK">UK</Radio>
              <Radio value="Ireland">Ireland</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="addressLine1"
            label="Address line 1"
            rules={[{ required: true, message: "Please enter your address" }]}>
            <Input placeholder="Address line 1" />
          </Form.Item>

          <Form.Item name="addressLine2" label="Address line 2">
            <Input placeholder="Address line 2" />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            name="townCity"
            label="Town/City"
            rules={[{ required: true, message: "Please enter your town/city" }]}>
            <Input placeholder="Town/City" />
          </Form.Item>

          <Form.Item
            name="county"
            label="County">
            <Input placeholder="County" />
          </Form.Item>

          <Form.Item
            name="postCode"
            label="Postcode"
            rules={[{ required: true, message: "Please enter your postcode" }]}>
            <Input placeholder="Postcode" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={50}>
        <Col>
          <h2>Agreements</h2>
          <Form.Item name="agreements">
            <Checkbox.Group style={{ width: "100%" }}>
              <Checkbox value="agreePackages">
                I would like to receive products to the above address.
              </Checkbox>
              <br />
              <Checkbox value="agreeNewsletter">
                I would like to receive promotional email about {store.brand} products.
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={50}>
        <Col md={12} xs={24}>
          <h2>{type === "update-customer" ? "Set new password" : "Password"}</h2>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                message: "Please enter a password",
                required: window.location.pathname === "/register" ? true : false
              }
            ]}>
            <Input placeholder="Password" type="password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            rules={[
              {
                message: "Please confirm your password",
                required: window.location.pathname === "/register" ? true : false
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords do not match");
                }
              })
            ]}>
            <Input placeholder="Confirm Password" type="password" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      {error && <Alert message={error} type="error" showIcon />}

      <Row>
        <Col span={24}>
          <div className="button-bar">
            <Link to={type === "update-customer" ? "/products" : "/"}>
              <Button loading={loading} disabled={loading} htmlType="button" size="large">
                Back
              </Button>
            </Link>

            <Button loading={loading} disabled={loading} type="primary" htmlType="submit" size="large">
              {type === "update-customer" ? "Save" : "Register"}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export { CustomerForm };
