import React, { useContext } from "react";
import Layout from "../../components/Layout";
import { GlobalStateContext } from "../../Store";
import { CustomerForm } from "../../components/CustomerForm";
import { Divider } from "antd";


const Register = () => {
  const { store } = useContext(GlobalStateContext);

  return (
    <Layout showProfile={false}>
      <h1>Create your account</h1>
      <p>
        With your account you can register all the {store.brand} products used in your household in one
        place.
      </p>
      <p>
        If this is for business use, please indicate this in the account information.
      </p>
      <Divider />
      <CustomerForm type="create-customer" />

    </Layout>
  );
};

export default Register;
