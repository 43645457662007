import React from "react";
import Layout from "../../components/Layout";
import { Button } from "antd";
import { Link } from "react-router-dom";

const ThankYou = () => {
  return (
    <Layout documentTitle="Thank you">
      <h1>Thank you for registering your {} product</h1>
      <p>
        We hope you enjoy using your product as much as we do! You will receive a confirmation email
        with all the details you have supplied today.
      </p>

      <div className="button-bar">
        <Link to="/products">
          <Button htmlType="button" size="large">
            Back to my account
          </Button>
        </Link>

        <Link to="/register-product">
          <Button htmlType="button" size="large" type="primary">
            Register another product
          </Button>
        </Link>
      </div>
    </Layout>
  );
};

export default ThankYou;
